<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {ref, computed} from "vue";
import {useStore} from "vuex";
import SubNav from "@/components/SubNavRelevance";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country'],
  setup(props){
    const store              = useStore();
    const items              = computed( () => store.getters.getCountryIndices(props.country));
    const indices            = store.getters.getIndicesFromAxis(2, items);
    const avaliableIndices   = indices.filter(d => d.value != null );
    const unavaliableIndices = indices.filter(d => d.value == null);

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      format : store.getters.formatNumber,
      show : ref(true),
      indices,
      avaliableIndices,
      unavaliableIndices,
      items
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
    SubNav
  }
}
</script>



<template>
  <div>
    <section>
      <h1 class="mb-4 pb-4">
        <strong>Eje 2.</strong> Pertinencia de la inversión en
        <strong>{{ country.name }}</strong>
      </h1>

      <!-- indicadores disponibles-->
      <h3>
        {{ avaliableIndices.length }} indicadores disponibles de
        {{ indices.length }} posibles en el eje 2
      </h3>
      <div class="mb-3">
        <span
          style="
            display: inline-block;
            height: 15px;
            width: 100%;
            background: #f2f2f0;
          ">
          <span
            :style="{
              display: 'inline-block',
              width: `${(avaliableIndices.length * 100) / (indices.length)}%`,
              height: '15px',
              background: '#e9b03b',
            }"></span>
        </span>
      </div>
      <p class="mb-0">
        <span
          :style="{
            display: 'inline-block',
            height: '10px',
            background: '#e9b03b',
            width: '10px',
            margin: '0 5px 0 0',
          }"></span
        >Indicadores disponibles
      </p>
      <p class="mb-4 pb-4">
        <span class="im_stacked_amount"
          >{{
            format(
              (avaliableIndices.length * 100) / (indices.length)
            )
          }}%</span>
      </p>

      <p class="mb-4 pb-4">La pertinencia del gasto indica que la inversión está siendo realizada en respuesta a las necesidades, políticas y prioridades tanto nacionales como internacionales de las personas con VIH, así como a los compromisos internacionales suscritos por los países. </p>

      <sub-nav />

      <router-view :country="country" :indices="avaliableIndices" :items="items" />
    </section>

    <div>
      <section class="od_noindex">
        <h3 class="mb-3"><strong>{{unavaliableIndices.length}} indicadores no dispobibles</strong> en el eje 2 en {{country.name}}</h3>
        <button v-on:click="show = !show" class="od_btn_noindex mb-4">
        {{show ? 'Ocultar' : 'Mostrar'}} indicadores no registrados
      </button>
      <ol v-if="show" class="od_list_noindex">
        <li v-for="el in unavaliableIndices" :key="el">{{el.name}}</li>
      </ol>
      </section>
    </div>
  </div>
</template>