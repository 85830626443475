<template>
  <div class="row mb-4 pb-4">
    <div class="col mb-4">
      <router-link :to="{ name: 'RelevanceObjective1'}" class="od_nav_sub">
        Objetivo 1
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'RelevanceObjective4'}" class="od_nav_sub">
        Objetivo 4
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'RelevanceObjective9'}" class="od_nav_sub">
        Objetivo 9
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'RelevanceObjective10'}" class="od_nav_sub">
        Objetivo 10
      </router-link>
    </div>
  </div>
</template>